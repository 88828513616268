import React, { useState, useEffect } from "react";
import alarm from "./alarm.mp3";

const Timer = () => {
	const [second, setSecond] = useState("00");
	const [minute, setMinute] = useState("15");
	const [isActive, setIsActive] = useState(false);
	const [counter, setCounter] = useState(15 * 60);
	const [sessionLength, setSessionLength] = useState(15);
	const [breakLength, setBreakLength] = useState(5);
	const [isSession, setIsSession] = useState(true);
	const [sessionCount, setSessionCount] = useState(0);
	const [firstClick, setFirstClick] = useState(true);

	const audio = new Audio("");

	const increaseSessionLength = () => {
		if (sessionLength === 60) return;
		var length = sessionLength;
		length++;
		setSessionLength(length);

		if (!isSession) return;
		setCounter(length * 60);
		setMinute(String(length).length === 1 ? `0${length}` : length);
		setSecond("00");
	};

	const decreaseSessionLength = () => {
		if (sessionLength === 1) return;
		var length = sessionLength;
		length--;
		setSessionLength(length);

		if (!isSession) return;
		setCounter(length * 60);
		setMinute(String(length).length === 1 ? `0${length}` : length);
		setSecond("00");
	};

	const increaseBreakLength = () => {
		if (breakLength === 60) return;
		var length = breakLength;
		length++;
		setBreakLength(length);

		if (isSession) return;
		setCounter(length * 60);
		setMinute(String(length).length === 1 ? `0${length}` : length);
		setSecond("00");
	};

	const decreaseBreakLength = () => {
		if (breakLength === 1) return;
		var length = breakLength;
		length--;
		setBreakLength(length);

		if (isSession) return;
		setCounter(length * 60);
		setMinute(String(length).length === 1 ? `0${length}` : length);
		setSecond("00");
	};

	const handleStartPause = () => {
		setIsActive(!isActive);
		if (firstClick === true) {
			audio.src = "";
			audio.play();
			setFirstClick(false);
		}
	};

	const reset = () => {
		setSessionLength(15);
		setBreakLength(5);
		setCounter(15 * 60);
		setMinute(15);
		setSecond("00");
		setIsActive(false);
		setIsSession(true);
		setSessionCount(0);
	};

	useEffect(() => {
		console.log("Audio inititated.");
		audio.play();
	}, []);

	useEffect(() => {
		let intervalId;

		if (isActive) {
			if (counter < 0) {
				audio.src = alarm;
				audio.play();
				setCounter(isSession ? breakLength * 60 : sessionLength * 60);
				if (isSession) setSessionCount(sessionCount + 1);
				setIsSession(!isSession);
			}
			intervalId = setInterval(() => {
				const secondCounter = counter % 60;
				const minuteCounter = Math.floor(counter / 60);

				const computedSecond =
					String(secondCounter).length === 1
						? `0${secondCounter}`
						: secondCounter;
				const computedMinute =
					String(minuteCounter).length === 1
						? `0${minuteCounter}`
						: minuteCounter;

				setSecond(computedSecond);
				setMinute(computedMinute);

				setCounter((counter) => counter - 1);
			}, 1000);
		}

		return () => clearInterval(intervalId);
	}, [
		isActive,
		counter,
		isSession,
		breakLength,
		sessionLength,
		sessionCount,
	]);

	const buildTimerButtons = () => {
		return (
			<div className="col-12 d-flex justify-content-between mt-4">
				<button
					onClick={handleStartPause}
					className="start col-5 btn rounded"
					style={{
						backgroundColor: "maroon",
						color: "white",
						height: "4rem",
					}}
				>
					{isActive ? "Pause" : "Start"}
				</button>
				<button
					onClick={reset}
					className="reset col-5 btn rounded"
					style={{
						backgroundColor: "#D6D6D6",
						height: "4rem",
					}}
				>
					Reset
				</button>
			</div>
		);
	};

	const buildTimer = () => {
		return (
			<div
				className="col-12 d-flex mb-2 rounded"
				style={{
					backgroundColor: "#D6D6D6",
				}}
			>
				<div className="col-7 d-flex justify-content-start px-3 py-1">
					{isSession ? "Session" : "Break"}
				</div>
				<div
					className="col-5 d-flex justify-content-center px-3 py-1 rounded-end"
					style={{
						backgroundColor: "#DFDFDF",
					}}
				>
					<span className="minute">{minute}</span>
					<span>:</span>
					<span className="second">{second}</span>
				</div>
			</div>
		);
	};

	const buildSessionCount = () => {
		return (
			<div
				className="col-12 d-flex mb-2 rounded"
				style={{
					backgroundColor: "#D6D6D6",
				}}
			>
				<div className="col-7 d-flex justify-content-start px-3 py-1">
					Session Count
				</div>
				<div
					className="col-5 d-flex justify-content-center px-3 py-1 rounded-end"
					style={{
						backgroundColor: "#DFDFDF",
					}}
				>
					{sessionCount}
				</div>
			</div>
		);
	};

	const buildSessionLength = () => {
		return (
			<div
				className="col-12 d-flex mb-2 rounded"
				style={{
					backgroundColor: "#D6D6D6",
				}}
			>
				<div className="col-7 d-flex justify-content-start px-3 py-1">
					Session Length
				</div>
				<div
					className="col-5 d-flex justify-content-center rounded-end"
					style={{
						backgroundColor: "#DFDFDF",
					}}
				>
					<button
						type="button"
						onClick={decreaseSessionLength}
						className="subtract btn col-4"
						disabled={isActive}
						style={{
							backgroundColor: "#DFDFDF",
						}}
					>
						-
					</button>
					<span
						className="col-4 d-flex justify-content-center align-items-center"
						style={{
							backgroundColor: "#e6e6e6",
						}}
					>
						{sessionLength}
					</span>
					<button
						type="button"
						onClick={increaseSessionLength}
						className="add btn col-4"
						disabled={isActive}
						style={{
							backgroundColor: "#DFDFDF",
						}}
					>
						+
					</button>
				</div>
			</div>
		);
	};

	const buildBreakLength = () => {
		return (
			<div
				className="col-12 d-flex mb-2 rounded"
				style={{
					backgroundColor: "#D6D6D6",
				}}
			>
				<div className="col-7 d-flex justify-content-start px-3 py-1">
					Break Length
				</div>
				<div
					className="col-5 d-flex justify-content-center rounded-end"
					style={{
						backgroundColor: "#DFDFDF",
					}}
				>
					<button
						type="button"
						onClick={decreaseBreakLength}
						className="subtract btn col-4"
						disabled={isActive}
						style={{
							backgroundColor: "#DFDFDF",
						}}
					>
						-
					</button>
					<span
						className="col-4 d-flex justify-content-center align-items-center"
						style={{
							backgroundColor: "#e6e6e6",
						}}
					>
						{breakLength}
					</span>
					<button
						type="button"
						onClick={increaseBreakLength}
						className="add btn col-4"
						disabled={isActive}
						style={{
							backgroundColor: "#DFDFDF",
						}}
					>
						+
					</button>
				</div>
			</div>
		);
	};

	return (
		<div
			className="mx-2 card p-4"
			style={{
				backgroundColor: "#CACACA",
				borderRadius: "1rem",
			}}
		>
			<div className="mb-2">
				{buildSessionLength()}
				{buildBreakLength()}
			</div>
			<div className="col-12 mb-2">
				{buildTimer()}
				{buildSessionCount()}
				{buildTimerButtons()}
			</div>
		</div>
	);
};

export default Timer;
