import MusicPlayer from "./components/Music/MusicPlayer";
import Timer from "././components/Timer/Timer";
import TextEditor from "./components/TextEditor/TextEditor";
import Message from "./components/Message/Message";
import Header from "./components/Header/Header";
import "./App.css";

function App() {
	return (
		<div className="row d-flex align-items-stretch h-100 font-face-m-bold">
			<div
				className="col-12 col-lg-3 p-4"
				style={{
					backgroundColor: "#E6E6E6",
				}}
			>
				<Header />
				<MusicPlayer />
				<Timer />
			</div>
			<div
				className="col-12 col-lg-9 p-4"
				style={{
					backgroundColor: "#EBEBEB",
				}}
			>
				<Message />
				<TextEditor />
			</div>
		</div>
	);
}

export default App;
