import React from "react";

const Message = () => {
	return (
		<div
			className="col-12 p-3 card"
			style={{
				backgroundColor: "#CECECE",
				fontSize: "1rem",
			}}
		>
			<h4>
				Want to Restore Your Brains Natural Ability to Focus, 
				Concentrate, and Accomplish Difficult things?
			</h4>
			<p className="font-face-m-light">
				You've been using FocusFlow.app to stay focused and now it's
				time to take your Focus & Concentration abilities to the next level. Join The Focus Group where
				you'll have LIVE focus-building challenges, accountability
				that won't let you fail, and get the motivation you need to make mental breakthrough after breakthrough. Join us!
			</p>
			<a href="https://www.diveej.com/join-focus-group">
				<button
					className="btn col-12 col-md-4"
					style={{
						backgroundColor: "#15c399",
						color: "white",
					}}
				>
					YES, I WANT ACCESS
				</button>
			</a>
		</div>
	);
};

export default Message;
