import React, { useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import musicList from "./Music";
import "./music.css";

const MusicPlayer = () => {
	const [musicIndex, setMusicIndex] = useState(0);

	const changeMusic = (index) => {
		setMusicIndex(index);
	};

	const handleMusicEnd = () => {
		const newIndex = (musicIndex + 1) % musicList.length;
		setMusicIndex(newIndex);
	};

	const buildPlaylist = () => {
		return (
			<div className="px-4">
				<h3>PLAYLISTS</h3>
				<div
					style={{
						height: "120px",
						overflow: "hidden",
						overflowY: "scroll",
					}}
				>
					{musicList.map((music, index) => {
						return (
							<div
								className="mb-1 playlist-item"
								key={index}
								onClick={() => changeMusic(index)}
							>
								<img
									className="col-3 playlist-image"
									src={music.image}
									alt=""
								/>
								<span className="col-9 ms-2">
									{music.label}
								</span>
							</div>
						);
					})}
				</div>
			</div>
		);
	};
	return (
		<div className="col-12">
			{buildPlaylist()}
			<ReactAudioPlayer
				className="col-12 px-2 my-2"
				src={musicList[musicIndex].audio}
				autoPlay={true}
				controls
				onEnded={handleMusicEnd}
			/>
		</div>
	);
};

export default MusicPlayer;
