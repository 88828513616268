import React from "react";
import logo from "./logo2.png";

const Header = () => {
	return (
		<div>
			<div className="col-12 d-flex justify-content-center">
				<img
					src={logo}
					alt="Redzone"
					style={{
						height: "3rem",
						padding: "0.2rem",
					}}
					className="img-responsive"
				/>
			</div>
			<hr />
		</div>
	);
};

export default Header;
