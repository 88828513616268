import sound1 from "./musics/1-hour-meditation-music-alpha-brain-waves-for-deep-relaxation-and-creative-thinking.mp3";
import sound2 from "./musics/alpha+waves.mp3";
import sound3 from "./musics/Flow State Music 8D Audio - Binaural Theta Waves - Improve Concentration.mp3";
import image1 from "./musics/song1.jpeg";
import image2 from "./musics/song2.jpg";
import image3 from "./musics/song3.jpg";

const musicList = [
	{
		audio: sound1,
		label: "Alpha Waves 1",
		image: image1,
	},
	{
		audio: sound2,
		label: "Alpha Waves 2",
		image: image2,
	},
	{
		audio: sound3,
		label: "Alpha Waves 3",
		image: image3,
	},
];

export default musicList;
